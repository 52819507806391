import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";
import styles from "./styles.css";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default () => {
  return (
    <>
      <Card
        sx={{
          mt: 3,
          ml: 1,
          mr: 1,
          boxShadow: 3,
          borderRadius: 2,
          // width: {
          //   xs: 300, // theme.breakpoints.up('xs')
          //   sm: 400, // theme.breakpoints.up('sm')
          //   md: 500, // theme.breakpoints.up('md')
          //   lg: 600, // theme.breakpoints.up('lg')
          //   xl: 700, // theme.breakpoints.up('xl')
          // },
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            Welcome to Super Admin Pannel of <b>Mealster </b>
          </Typography>
          <br />
          <Typography variant="h5" component="div">
            <b>Foodingo</b> Private Limited
          </Typography>
          <br />
          <Typography sx={{ mt: 2 }} color="text.secondary">
            Website made by{" "}
            <Link
              sx={{ fontWeight: 600, fontStyle: "Didot" }}
              href="https://myselfshravan.github.io"
              underline="none"
            >
              {"Shravan"}
            </Link>
          </Typography>
        </CardContent>
      </Card>
      {/* <Card sx={{ mt: 3, ml: 1, mr: 1, mb: 0, boxShadow: 2, borderRadius: 1 }}>
        <CardActions>
          <Button
            href="/#/Users"
            size="medium"
            variant="contained"
            boxShadow="2"
            sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
          >
            Users
          </Button>
          <Button
            href="/#/Cooks"
            size="medium"
            variant="contained"
            boxShadow="2"
            sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
          >
            Cooks
          </Button>
          <Button
            href="/#/Blogs"
            size="medium"
            variant="contained"
            boxShadow="2"
            sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
          >
            Blogs
          </Button>
        </CardActions>
      </Card> */}
    </>
  );
};

//   <>
//     <Card
//       sx={{
//         mt: 3,
//         ml: 1,
//         mr: 1,
//         boxShadow: 3,
//         borderRadius: 2,
//         // width: {
//         //   xs: 300, // theme.breakpoints.up('xs')
//         //   sm: 400, // theme.breakpoints.up('sm')
//         //   md: 500, // theme.breakpoints.up('md')
//         //   lg: 600, // theme.breakpoints.up('lg')
//         //   xl: 700, // theme.breakpoints.up('xl')
//         // },
//       }}
//     >
//       <CardContent>
//         <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
//           Welcome to Admin Webpage
//         </Typography>
//         <br />
//         <Typography variant="h5" component="div">
//           Indigo Infrastructures Private Limited
//         </Typography>
//         <br />
//         <Typography sx={{ mt: 2 }} color="text.secondary">
//           Website made by{' '}
//           <Link
//             sx={{ fontWeight: 600, fontStyle: 'Didot' }}
//             href="https://myselfshravan.github.io"
//             underline="none"
//           >
//             {'Shravan'}
//           </Link>
//         </Typography>
//         {/* <Typography variant="body2">
//       well meaning and kindly.
//       <br />
//       {'"a benevolent smile"'}
//     </Typography> */}
//       </CardContent>
//     </Card>
//     <Card>
//       {clients.map((item, index) => (
//         <Text as="h2" content={item.content} />
//       ))}
//     </Card>
//     <Card sx={{ mt: 3, ml: 1, mr: 1, mb: 0, boxShadow: 2, borderRadius: 1 }}>
//       <CardActions>
//         <Button
//           href="/#/Users"
//           size="medium"
//           variant="contained"
//           boxShadow="2"
//           sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
//         >
//           Users
//         </Button>
//         <Button
//           href="/#/Cooks"
//           size="medium"
//           variant="contained"
//           boxShadow="2"
//           sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
//         >
//           Cooks
//         </Button>
//         <Button
//           href="/#/Blogs"
//           size="medium"
//           variant="contained"
//           boxShadow="2"
//           sx={{ mt: 1, ml: 1, mr: 1, mb: 1 }}
//         >
//           Blogs
//         </Button>
//       </CardActions>
//     </Card>
//   </>;
// };
