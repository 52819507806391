export const firebaseConfig = {
  apiKey: 'AIzaSyCnlpD5DSecqNQzgwwUUbW-BZyz-FuIlb0',
  authDomain: 'foodey-63192.firebaseapp.com',
  databaseURL: 'https://foodey-63192-default-rtdb.firebaseio.com',
  projectId: 'foodey-63192',
  storageBucket: 'foodey-63192.appspot.com',
  messagingSenderId: '1056375278651',
  appId: '1:1056375278651:web:5784ec975990b10c65a01e',
  measurementId: 'G-G77NTF3JMK',
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyACyiB2f-Sl8fbez4sjwBxJwn-eGadnXcg",
//   authDomain: "auth-44578.firebaseapp.com",
//   projectId: "auth-44578",
//   storageBucket: "auth-44578.appspot.com",
//   messagingSenderId: "595971213871",
//   appId: "1:595971213871:web:432717a56846feb84a14da",
//   measurementId: "G-BJWWD8H4BX"
// }
